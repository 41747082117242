<template lang='pug'>
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1 proton.ai
  el-form(v-if="companyIdentified", :model='form', :rules='rules', ref='loginForm', 
          v-loading='loading', @keyup.enter.native='login')
    el-form-item(prop='email')
      el-input(v-model='form.email', placeholder='Email', :autofocus='!invalidAttempt')
    el-form-item(prop='password', :class='{ "is-error": invalidAttempt }')
      el-input(v-model='form.password', type='password', placeholder='Password', :autofocus='invalidAttempt')
    div.error
      span(v-if='invalidAttempt') Invalid email or password
    div.login-signup
      el-form-item
        el-button.submit(type='primary', @click='login') Log in
      router-link(to='/create')
        el-button.sign-up(type='info') Sign up
    router-link(to='/forgot')
      el-button(type='text') Reset my password

  div(v-else)
    h3 🚫 Oops! You've landed in the wrong place.
    p To log into Proton, please visit your company-specific Proton URL (yourcompany.proton.ai)

  form(:action='`${$store.state.baseURL}login`', method='POST', id='secretForm')
    input(v-model='form.email', type='hidden', name='email')
    input(v-model='form.password', type='hidden', name='password')
    input(v-model='form.redirect', type='hidden', name='redirect')
</template>

<script>
import Cookies from 'js-cookie'
import { extractCompanyFromUrl } from '../utils'

export default {
  name: 'Login',
  data () {
    let clearAttempt = (rule, value, callback) => {
      this.invalidAttempt = false
      callback()  
    }

    return {
      invalidAttempt: false,
      companyIdentified: false,
      loading: false,
      form: {
        email: null,
        password: null,
        redirect: null
      },
      rules: {
        email: [
          { type: 'email', message: 'Enter a valid email address', trigger: 'blur' }
        ],
        password: [
          { validator: clearAttempt, trigger: 'change' }
        ]
      }
    }
  },
  methods: {
    login () {
      this.$refs.loginForm.validate((valid) => {
        if (valid) {
          this.loading = true
          document.forms['secretForm'].submit()
        }
      })
    }
  },
  mounted () {
    const redirect = this.$route.query.redirect
    this.form.redirect = redirect
    this.form.email = this.$route.query.email

    const company = extractCompanyFromUrl(redirect)
    if (company) {
      Cookies.set('company', company, { path: '', domain: '.proton.ai' })
      this.companyIdentified = true
    }

    if (this.$route.query.hasOwnProperty('invalid')) {
      this.invalidAttempt = true
    }
  }
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>